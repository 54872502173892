<template>
            <div class="grid">
                <div class="col-12 text-center">
                    <h2>Unlimited presentations, your own hosting </h2>
                    <p>Use the images from your own server and use any number of presentations on a page.
                        No need for subscriptions and external hosting of your images.
                        This viewer supports inertia and speed. So you can adjust the speed of rotating and slowing down
                        for what is
                        best for the
                        user experience. Create for example advanced animation intro's with the use of promises in
                        javascript.
                    </p>
                </div>
            </div>

</template>
<script lang="ts">
export default {}
</script>