<template>
    <div class="compare-versions">
        <div class="grid">
            <div class="col-12">
                <h2 class="text-center">360 Javascript Viewer</h2>
                <p class="text-center">A license is a one time payment and bound to a single domain.
                    If you use the latest version from the CDN or NPM you receive new features and bugfixes
                    automatically.
                </p>
            </div>
        </div>
        <div class="grid">
            <div class="col-12">
                <div class="table">
                    <table class="table table-striped table-hover">
                        <thead class="thead-inverse">
                        <tr>
                            <th class="option"></th>
                            <th class="">Free</th>
                            <th class="">
                                <span class="">{{ price }}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="option">Number of 360 presentations</td>
                            <td class="">unlimited</td>
                            <td class="">unlimited</td>
                        </tr>
                        <tr>
                            <td class="option">Number of views</td>
                            <td class="">unlimited</td>
                            <td class="">unlimited</td>
                        </tr>
                        <tr>
                            <td class="option">Number of images</td>
                            <td class="">2 to 365</td>
                            <td class="">2 to 365</td>
                        </tr>
                        <tr>
                            <td class="option">Unlimited Storage</td>
                            <td class="">use your own</td>
                            <td class="">use your own</td>
                        </tr>
                        <tr>
                            <td class="option">Tech Support</td>
                            <td><i class="icon-cross"></i></td>
                            <td>Email</td>
                        </tr>
                        <tr>
                            <td class="option">No powered by icon</td>
                            <td><i class="icon-cross"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td class="option">Multiple 360 presentations on the same page</td>
                            <td><i class="icon-checkmark"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td class="option">Zoom option</td>
                            <td><i class="icon-checkmark"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td class="option">Free Wordpress plugin</td>
                            <td><i class="icon-checkmark"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td class="option">Inertia & speed control</td>
                            <td><i class="icon-checkmark"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td class="option">Rotate on start option</td>
                            <td><i class="icon-checkmark"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td class="option">Full API control</td>
                            <td><i class="icon-checkmark"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td class="option">CDN resizing support <a target="_blank" class="small" href="https://www.gumlet.com/?fpr=3dweb">(good option)</a></td>
                            <td><i class="icon-checkmark"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td class="option">Custom start notification</td>
                            <td><i class="icon-checkmark"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td class="option">Custom loading bar</td>
                            <td><i class="icon-checkmark"></i></td>
                            <td><i class="icon-checkmark"></i></td>
                        </tr>
                        <tr>
                            <td colspan="1">
                                <a href="/api" class="btn btn-primary btn-outline">All features in API documentation</a>
                            </td>
                            <td class="td-buybutton" colspan="2" >
                                <BuyButton  size="small"></BuyButton>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>
<script setup lang="ts">
import {getPricing} from "@/modules/pricing";

const price = ref(getPricing())
</script>
<style scoped>
.td-buybutton{
    text-align: right;
    
    @media (max-width: $md) {
        button{
            right: 20px;
            position: absolute;
        }
    }
}
table th{
    text-align: left;
    padding-left: 12px;
}
.icon-checkmark {
    color: green;
    font-weight: bold;
}
.icon-cross {
    color: red;
    font-weight: bold;
}
</style>